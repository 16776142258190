/**
 * @note Locale authority is `whispli-api`. `@whispli/locales` is published during `whispli-api` CI.
 */
import _API_LOCALES from '@whispli/locales/api-locales.json' assert { type: 'json' }
import _API_TO_ICU from '@whispli/locales/api-to-icu.json' assert { type: 'json' }
import _API_TO_PHRASE from '@whispli/locales/api-to-phrase.json' assert { type: 'json' }
import _AWS_LOCALES from '@whispli/locales/aws.json' assert { type: 'json' }
import _DEEP_L_SOURCE_LOCALES from '@whispli/locales/deep-l-source.json' assert { type: 'json' }
import _DEEP_L_TARGET_LOCALES from '@whispli/locales/deep-l-target.json' assert { type: 'json' }
import _PHRASE_TO_API from '@whispli/locales/phrase-to-api.json' assert { type: 'json' }

export const LOCALE_ENUM = Object.freeze({
  AF: _API_LOCALES['af'].code as 'af',
  AR: _API_LOCALES['ar'].code as 'ar',
  AS: _API_LOCALES['as'].code as 'as',
  BE: _API_LOCALES['be'].code as 'be',
  BG: _API_LOCALES['bg'].code as 'bg',
  BN: _API_LOCALES['bn'].code as 'bn',
  BS: _API_LOCALES['bs'].code as 'bs',
  CS: _API_LOCALES['cs'].code as 'cs',
  DA: _API_LOCALES['da'].code as 'da',
  DE: _API_LOCALES['de'].code as 'de',
  EL: _API_LOCALES['el'].code as 'el',
  EN: _API_LOCALES['en'].code as 'en',
  EN_US: _API_LOCALES['en'].code as 'en',
  ES: _API_LOCALES['es'].code as 'es',
  ES_ES: _API_LOCALES['es'].code as 'es',
  ET: _API_LOCALES['et'].code as 'et',
  FA: _API_LOCALES['fa'].code as 'fa',
  FI: _API_LOCALES['fi'].code as 'fi',
  FJ: _API_LOCALES['fj'].code as 'fj',
  FR: _API_LOCALES['fr'].code as 'fr',
  FR_FR: _API_LOCALES['fr'].code as 'fr',
  GU: _API_LOCALES['gu'].code as 'gu',
  HE: _API_LOCALES['he'].code as 'he',
  HI: _API_LOCALES['hi'].code as 'hi',
  HR: _API_LOCALES['hr'].code as 'hr',
  HU: _API_LOCALES['hu'].code as 'hu',
  HY: _API_LOCALES['hy'].code as 'hy',
  ID: _API_LOCALES['id'].code as 'id',
  IT: _API_LOCALES['it'].code as 'it',
  JA: _API_LOCALES['ja'].code as 'ja',
  KA: _API_LOCALES['ka'].code as 'ka',
  KK: _API_LOCALES['kk'].code as 'kk',
  KN: _API_LOCALES['kn'].code as 'kn',
  KO: _API_LOCALES['ko'].code as 'ko',
  KM: _API_LOCALES['km'].code as 'km',
  LG: _API_LOCALES['lg'].code as 'lg',
  LT: _API_LOCALES['lt'].code as 'lt',
  LV: _API_LOCALES['lv'].code as 'lv',
  MK: _API_LOCALES['mk'].code as 'mk',
  ML: _API_LOCALES['ml'].code as 'ml',
  MN: _API_LOCALES['mn'].code as 'mn',
  MS_MY: _API_LOCALES['ms-my'].code as 'ms-my',
  MY: _API_LOCALES['my'].code as 'my',
  NE: _API_LOCALES['ne'].code as 'ne',
  NL: _API_LOCALES['nl'].code as 'nl',
  NO: _API_LOCALES['no'].code as 'no',
  OR: _API_LOCALES['or'].code as 'or',
  PA: _API_LOCALES['pa'].code as 'pa',
  PL: _API_LOCALES['pl'].code as 'pl',
  PT: _API_LOCALES['pt-pt'].code as 'pt-pt',
  PT_BR: _API_LOCALES['pt-br'].code as 'pt-br',
  PT_PT: _API_LOCALES['pt-pt'].code as 'pt-pt',
  RO: _API_LOCALES['ro'].code as 'ro',
  RU: _API_LOCALES['ru'].code as 'ru',
  SK: _API_LOCALES['sk'].code as 'sk',
  SL_SI: _API_LOCALES['sl-si'].code as 'sl-si',
  SQ: _API_LOCALES['sq'].code as 'sq',
  SR: _API_LOCALES['sr'].code as 'sr',
  /** @deprecated Use SR */
  SR_LATN: _API_LOCALES['sr-latn'].code as 'sr-latn',
  SV: _API_LOCALES['sv'].code as 'sv',
  SW: _API_LOCALES['sw'].code as 'sw',
  TA: _API_LOCALES['ta'].code as 'ta',
  TE: _API_LOCALES['te'].code as 'te',
  TH: _API_LOCALES['th'].code as 'th',
  TL: _API_LOCALES['tl'].code as 'tl',
  TR: _API_LOCALES['tr'].code as 'tr',
  UK: _API_LOCALES['uk'].code as 'uk',
  UR: _API_LOCALES['ur'].code as 'ur',
  UZ: _API_LOCALES['uz'].code as 'uz',
  VI: _API_LOCALES['vi'].code as 'vi',
  XOG: _API_LOCALES['xog'].code as 'xog',
  YUE: _API_LOCALES['yue'].code as 'yue',
  ZH_CN: _API_LOCALES['zh-cn'].code as 'zh-cn',
  ZH_HANT: _API_LOCALES['zh-hant'].code as 'zh-hant',
  ZU: _API_LOCALES['zu'].code as 'zu',
})

export type LocaleCode = typeof _API_LOCALES[keyof typeof _API_LOCALES]['code']

export const API_LOCALES = _API_LOCALES as Record<LocaleCode, Locale>
export const API_TO_ICU = _API_TO_ICU
export const API_TO_PHRASE = _API_TO_PHRASE
export const AWS_LOCALES = _AWS_LOCALES
export const DEEP_L_SOURCE_LOCALES = _DEEP_L_SOURCE_LOCALES
export const DEEP_L_TARGET_LOCALES = _DEEP_L_TARGET_LOCALES
export const PHRASE_TO_API = _PHRASE_TO_API

export interface Locale {
  /**
   * @note This is the code utilized by Tenant API. It is **mostly** ISO 639 compliant.
   * All Whispli services and consumers of the Tenant API MUST accept these locale codes.
   * All Whispli services and consumers of the Tenant API MUST provide these locale codes.
   */
  code: LocaleCode;
  /**
   * @see https://help.phrase.com/help/managing-locales-and-languages
   */
  default?: boolean;
  localizedName: string;
  /**
   * @see https://help.phrase.com/help/managing-locales-and-languages
   */
  main?: boolean;
  name: string;
  rtl: boolean;
  deprecated?: boolean;
}

export const AF = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.AF] }) as Readonly<Locale>

export const AR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.AR] }) as Readonly<Locale>

export const AS = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.AS] }) as Readonly<Locale>

export const BE = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.BE] }) as Readonly<Locale>

export const BG = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.BG] }) as Readonly<Locale>

export const BN = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.BN] }) as Readonly<Locale>

export const BS = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.BS] }) as Readonly<Locale>

export const CS = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.CS] }) as Readonly<Locale>

export const DA = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.DA] }) as Readonly<Locale>

export const DE = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.DE] }) as Readonly<Locale>

export const EL = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.EL] }) as Readonly<Locale>

export const EN_US = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.EN_US] }) as Readonly<Locale>

export const EN = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.EN] }) as Readonly<Locale>

export const ES_ES = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ES_ES] }) as Readonly<Locale>

export const ES = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ES] }) as Readonly<Locale>

export const ET = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ET] }) as Readonly<Locale>

export const FA = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.FA] }) as Readonly<Locale>

export const FI = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.FI] }) as Readonly<Locale>

export const FJ = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.FJ] }) as Readonly<Locale>

export const FR_FR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.FR_FR] }) as Readonly<Locale>

export const FR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.FR] }) as Readonly<Locale>

export const GU = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.GU] }) as Readonly<Locale>

export const HE = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.HE] }) as Readonly<Locale>

export const HI = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.HI] }) as Readonly<Locale>

export const HR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.HR] }) as Readonly<Locale>

export const HU = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.HU] }) as Readonly<Locale>

export const HY = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.HY] }) as Readonly<Locale>

export const ID = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ID] }) as Readonly<Locale>

export const IT = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.IT] }) as Readonly<Locale>

export const JA = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.JA] }) as Readonly<Locale>

export const KA = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.KA] }) as Readonly<Locale>

export const KK = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.KK] }) as Readonly<Locale>

export const KM = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.KM] }) as Readonly<Locale>

export const KN = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.KN] }) as Readonly<Locale>

export const KO = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.KO] }) as Readonly<Locale>

export const LG = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.LG] }) as Readonly<Locale>

export const LT = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.LT] }) as Readonly<Locale>

export const LV = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.LV] }) as Readonly<Locale>

export const MK = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.MK] }) as Readonly<Locale>

export const ML = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ML] }) as Readonly<Locale>

export const MN = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.MN] }) as Readonly<Locale>

export const MS_MY = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.MS_MY] }) as Readonly<Locale>

export const MY = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.MY] }) as Readonly<Locale>

export const NE = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.NE] }) as Readonly<Locale>

export const NL = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.NL] }) as Readonly<Locale>

export const NO = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.NO] }) as Readonly<Locale>

export const OR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.OR] }) as Readonly<Locale>

export const PA = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.PA] }) as Readonly<Locale>

export const PL = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.PL] }) as Readonly<Locale>

export const PT_BR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.PT_BR] }) as Readonly<Locale>

export const PT_PT = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.PT_PT] }) as Readonly<Locale>

export const RO = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.RO] }) as Readonly<Locale>

export const RU = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.RU] }) as Readonly<Locale>

export const SK = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.SK] }) as Readonly<Locale>

export const SL_SI = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.SL_SI] }) as Readonly<Locale>

export const SQ = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.SQ] }) as Readonly<Locale>

export const SR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.SR] }) as Readonly<Locale>

export const SR_LATN = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.SR_LATN] }) as Readonly<Locale>

export const SV = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.SV] }) as Readonly<Locale>

export const SW = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.SW] }) as Readonly<Locale>

export const TA = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.TA] }) as Readonly<Locale>

export const TE = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.TE] }) as Readonly<Locale>

export const TH = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.TH] }) as Readonly<Locale>

export const TL = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.TL] }) as Readonly<Locale>

export const TR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.TR] }) as Readonly<Locale>

export const UK = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.UK] }) as Readonly<Locale>

export const UR = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.UR] }) as Readonly<Locale>

export const UZ = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.UZ] }) as Readonly<Locale>

export const VI = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.VI] }) as Readonly<Locale>

export const XOG = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.XOG] }) as Readonly<Locale>

export const YUE = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.YUE] }) as Readonly<Locale>

export const ZH_CN = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ZH_CN] }) as Readonly<Locale>

export const ZH_HANT = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ZH_HANT] }) as Readonly<Locale>

export const ZU = Object.freeze({ ...API_LOCALES[LOCALE_ENUM.ZU] }) as Readonly<Locale>

export const DEFAULT_LOCALE = Object.freeze({ ...EN_US, default: true }) as Readonly<Locale>

export const DEFAULT_LOCALE_CODE = DEFAULT_LOCALE.code

export const DEFAULT_PHRASE_LOCALE_CODE = API_TO_PHRASE[DEFAULT_LOCALE.code]

const EXCLUDE = new Set<LocaleCode>([
  /** @note Use LOCALE_ENUM.SR_LATN */
  LOCALE_ENUM.SR,
])

/**
 * This is a list of languages **supported** by the Whispli platform.
 * Packages **MAY** select a *subset* or *all* of this list.
 *
 * A package **SHOULD** export the following from `<rootDir>/i18n/locales.ts`:
 * - `DEFAULT_LOCALE`
 * - `DEFAULT_LOCALE_CODE`
 * - `LOCALES`
 *
 * The `@whispli/phrase` integration expects a PhraseApp project to export `<rootDir>/i18n/locales.ts`.
 *
 * @example Export defaults
 *   export { DEFAULT_LOCALE, DEFAULT_LOCALE_CODE, LOCALES } from '@whispli/i18n/constants'
 * @example Export subset
 *   import { EN_US, FR_FR } from '@whispli/i18n/constants'
 *   import type { Locale } from '@whispli/i18n/types'
 *   export { EN_US, FR_FR } from '@whispli/i18n/constants'
 *
 *   export const LOCALES: ReadonlyArray<Locale> = [ EN_US, FR_FR ]
 *   export const DEFAULT_LOCALE = { ...FR_FR, default: true }
 *   export const DEFAULT_LOCALE_CODE = DEFAULT_LOCALE.code
 */
export const LOCALES: ReadonlyArray<Readonly<Locale>> = [
  AF,
  AR,
  AS,
  BE,
  BG,
  BN,
  BS,
  CS,
  DA,
  DE,
  DEFAULT_LOCALE,
  EL,
  ES_ES,
  ET,
  FA,
  FI,
  FJ,
  FR_FR,
  GU,
  HE,
  HI,
  HR,
  HU,
  HY,
  ID,
  IT,
  JA,
  KA,
  KK,
  KM,
  KN,
  KO,
  LG,
  LT,
  LV,
  MK,
  ML,
  MN,
  MS_MY,
  MY,
  NE,
  NL,
  NO,
  OR,
  PA,
  PL,
  PT_BR,
  PT_PT,
  RO,
  RU,
  SK,
  SL_SI,
  SQ,
  SR,
  SR_LATN,
  SV,
  SW,
  TA,
  TE,
  TH,
  TL,
  TR,
  UK,
  UR,
  UZ,
  VI,
  XOG,
  YUE,
  ZH_CN,
  ZH_HANT,
  ZU,
]
  .filter(e => !EXCLUDE.has(e.code))

export const LOCALE_CODES = new Set<LocaleCode>(LOCALES.map<LocaleCode>(e => e.code))

/**
 * @note core-web locales
 */
export const ADMIN_LOCALES = [
  DEFAULT_LOCALE,
  DA,
  FR_FR,
  NO,
  PT_BR,
  ES_ES,
  PL,
]
  .sort((a, b) => a.localizedName.localeCompare(b.localizedName))
  .map<Readonly<Locale>>(Object.freeze)

/**
 * @note Locales following the plural rule nplurals=1; plural=0;
 * @see https://www.unicode.org/cldr/charts/latest/supplemental/language_plural_rules.html
 * @see https://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
 */
export const NO_PLURAL_LOCALES = [
  AS,
  ID,
  JA,
  KA,
  KM,
  KO,
  MS_MY,
  MY,
  TH,
  VI,
  ZH_CN,
  ZH_HANT,
].map<Readonly<Locale>>(Object.freeze)

export { SYSTEM_LOCALES } from '@whispli/i18n/constants/system-locales'

export const TENANT_API_LOCALE_CODES = Object.freeze(Object.keys(API_LOCALES)) as ReadonlyArray<LocaleCode>

/**
 * @note Use these maps to resolve disparate locale code usage.
 * If a dependency does not support a member of `API_LOCALES`
 * then the implementation should attempt to use the package's equivalent of `DEFAULT_LOCALE_CODE`.
 */

export const DEFAULT_DAYJS_LOCALE_CODE = 'en' as const

/**
 * Map supported TENANT_API_LOCALE code to dayjs locale code
 * @example List unsupported locales
 *   yarn doctor diff dayjs/locale
 * @see https://github.com/moment/moment/tree/develop/locale
 */
export const DAYJS_LOCALE_MAP = Object.freeze({
  [LOCALE_ENUM.EN_US]: 'en',
  [LOCALE_ENUM.AS]: DEFAULT_DAYJS_LOCALE_CODE,
  [LOCALE_ENUM.FJ]: DEFAULT_DAYJS_LOCALE_CODE,
  [LOCALE_ENUM.HY]: 'hy-am',
  [LOCALE_ENUM.NO]: DEFAULT_DAYJS_LOCALE_CODE,
  [LOCALE_ENUM.OR]: DEFAULT_DAYJS_LOCALE_CODE,
  [LOCALE_ENUM.PA]: 'pa-in',
  [LOCALE_ENUM.PT_PT]: 'pt',
  [LOCALE_ENUM.SL_SI]: 'sl',
  [LOCALE_ENUM.SR_LATN]: 'sr',
  [LOCALE_ENUM.TL]: 'tl-ph',
  [LOCALE_ENUM.YUE]: 'zh-hk',
  [LOCALE_ENUM.ZH_HANT]: 'zh-tw',
  [LOCALE_ENUM.ZU]: DEFAULT_DAYJS_LOCALE_CODE,
})

const DEFAULT_VEE_VALIDATE_LOCALE_CODE = 'en' as const

/**
 * Map supported TENANT_API_LOCALE code to vee-validate locale code
 * @example List unsupported locales
 *   yarn doctor diff vee-validate/dist/locale
 * @see https://github.com/logaretm/vee-validate/tree/v3/locale
 */
export const VEE_VALIDATE_LOCALE_MAP = Object.freeze({
  [LOCALE_ENUM.AF]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.BE]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.BS]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.EN_US]: 'en',
  [LOCALE_ENUM.ES_ES]: 'es',
  [LOCALE_ENUM.FJ]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.FR_FR]: 'fr',
  [LOCALE_ENUM.HI]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.HY]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.KK]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.MK]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.MS_MY]: 'ms_MY',
  [LOCALE_ENUM.MY]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.NO]: 'nn_NO',
  [LOCALE_ENUM.PT]: 'pt_PT',
  [LOCALE_ENUM.PT_BR]: 'pt_BR',
  [LOCALE_ENUM.PT_PT]: 'pt_PT',
  [LOCALE_ENUM.SL_SI]: 'sl',
  [LOCALE_ENUM.SR_LATN]: 'sr_Latin',
  [LOCALE_ENUM.SW]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.TL]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.UR]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
  [LOCALE_ENUM.YUE]: 'zh_CN',
  [LOCALE_ENUM.ZH_CN]: 'zh_CN',
  [LOCALE_ENUM.ZH_HANT]: 'zh_TW',
  [LOCALE_ENUM.ZU]: DEFAULT_VEE_VALIDATE_LOCALE_CODE,
})

const DEFAULT_VUETIFY_LOCALE_CODE = 'en' as const

/**
 * Map supported TENANT_API_LOCALE code to vuetify locale code
 * @example List unsupported locales
 *   yarn doctor diff vuetify/es5/locale
 * @see https://github.com/vuetifyjs/vuetify/tree/master/packages/vuetify/src/locale
 */
export const VUETIFY_LOCALE_MAP = Object.freeze({
  [LOCALE_ENUM.BE]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.BG]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.BS]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.EN_US]: 'en',
  [LOCALE_ENUM.ES_ES]: 'es',
  [LOCALE_ENUM.FI]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.FJ]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.FR_FR]: 'fr',
  [LOCALE_ENUM.HI]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.HY]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.KK]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.MK]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.MN]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.MS_MY]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.MY]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.PT_BR]: 'pt',
  [LOCALE_ENUM.PT_PT]: 'pt',
  [LOCALE_ENUM.SL_SI]: 'sl',
  [LOCALE_ENUM.SK]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.SQ]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.SR]: 'sr-Cyrl',
  [LOCALE_ENUM.SR_LATN]: 'sr-Cyrl',
  [LOCALE_ENUM.SW]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.TL]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.UR]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.VI]: DEFAULT_VUETIFY_LOCALE_CODE,
  [LOCALE_ENUM.YUE]: 'zh-Hans', // Simplified Chinese
  [LOCALE_ENUM.ZH_CN]: 'zh-Hans', // Simplified Chinese
  [LOCALE_ENUM.ZH_HANT]: 'zh-Hant', // Traditional Chinese
  [LOCALE_ENUM.ZU]: DEFAULT_VUETIFY_LOCALE_CODE,
})

/**
 * @see https://gitlab.com:whispli/tenant-api-v2/whispli-api/src/develop/config/translation.php
 * @see https://gitlab.com/whispli/tenant-api-v2/whispli/-/packages
 * @deprecated
 */
export const TENANT_API_LOCALE_MAP = Object.freeze(
  Object.fromEntries(TENANT_API_LOCALE_CODES.map(code => [ code, code ])),
)

export const DIRECTION_LTR = 'ltr' as const
export const DIRECTION_RTL = 'rtl' as const

if (import.meta.hot) {
  import.meta.hot.accept()
}


